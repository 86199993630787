import React from "react"
import "./cta.module.scss"
import { navigate } from "gatsby"

class CTA extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {
        "Request from customer":
          "You have received a request for free SHAPE Report from the customer. Details are given below.",
      },
    }
  }
  encode(data) {
    console.log(data)
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleClickLaunch = e => {
    e.preventDefault()
    navigate("/launch")
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { isFreeReport } = this.props
    const { submitted, submitting } = this.state

    return (
      <div styleName="cta">
        <div styleName="card-free-report">
          {isFreeReport ? (
            <>
              <div styleName="card-heading">
                You’re in charge, get started anytime​
              </div>
              <div styleName="card-desc">
                Get in touch, we’d love to show you why we’re the new standard
                in employee engagement. It takes just 10 mins.​
              </div>
              <form
                name="Report-Request"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="free-report-form"
              >
                <div styleName="inputWrapper">
                  {!submitted && (
                    <input
                      type="text"
                      name="email"
                      id="free-report-input-email"
                      required
                      placeholder="Email address"
                      onChange={this.handleChange}
                    />
                  )}
                  <button
                    type="submit"
                    id="free-report-form-submit-button"
                    disabled={submitted || submitting}
                    className="text-white"
                    styleName={`${submitted ? "submittedButton" : ""}`}
                  >
                    {(submitted &&
                      "Got it! We'll be in touch as fast as we can.") ||
                      (submitting && "Submitting...") ||
                      "Get free report"}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <div styleName="card-heading">See results in one month​</div>
              <div styleName="card-desc">
                Survey your company with less hassle and start making changes
                faster than ever before​
              </div>
              <button
                id="cta-launch-btn"
                onClick={e => this.handleClickLaunch(e)}
              >
                Launch SHAPE
              </button>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default CTA
