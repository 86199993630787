import React, { useState } from "react"
import "./vertical-carousel.scss"

const VerticalCarousel = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const onMouseEnter = e => {
    e.preventDefault()
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    // if any scroll is attempted,
    // set this to the previous value
    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop)
    }
  }
  const onMouseLeave = e => {
    e.preventDefault()
    window.onscroll = function() {}
  }
  const [wheelCheck2, setWheelCheck2] = useState(false)
  const onWheelCarousel = e => {
    // e.preventDefault()
    e.persist()
    if (!wheelCheck2) {
      console.log("wheel check", wheelCheck2)
      setWheelCheck2(true)
      let contentText = document.getElementById("card-content-text")
      let contentImg = document.getElementById("card-content-img")
      let nxtPageIndx
      if (e.deltaY >= 0) {
        nxtPageIndx =
          currentPage === data.length - 1 ? data.length - 1 : currentPage + 1
      } else {
        nxtPageIndx = currentPage === 0 ? 0 : currentPage - 1
      }
      if (nxtPageIndx === currentPage) {
        window.onscroll = function() {}
        setTimeout(() => {
          setWheelCheck2(false)
        }, 1600)
        return
      }

      contentText.style.opacity = 0
      contentImg.style.opacity = 0
      setTimeout(() => {
        contentText.style.transform = directionDown
          ? "translateY(+5px)"
          : "translateY(-5px)"

        contentImg.style.transform = directionDown
          ? "translateY(+5px)"
          : "translateY(-5px)"
      }, 200)
      let directionDown = e.deltaY >= 0

      setTimeout(() => {
        setCurrentPage(nxtPageIndx)
        contentText.style.opacity = 1
        contentText.style.transform = directionDown
          ? "translateY(-5px)"
          : "translateY(+5px)"
        contentImg.style.opacity = 1
        contentImg.style.transform = directionDown
          ? "translateY(-5px)"
          : "translateY(+5px)"
      }, 400)

      setTimeout(() => {
        setWheelCheck2(false)
      }, 1600)
    }
  }
  const onChangeCarousel = (e, index) => {
    let contentText = document.getElementById("card-content-text")
    let contentImg = document.getElementById("card-content-img")
    contentText.style.opacity = 0
    contentImg.style.opacity = 0

    setTimeout(() => {
      contentText.style.transform = directionDown
        ? "translateY(+5px)"
        : "translateY(-5px)"

      contentImg.style.transform = directionDown
        ? "translateY(+5px)"
        : "translateY(-5px)"
    }, 200)
    let directionDown = index > currentPage

    setTimeout(() => {
      setCurrentPage(index)
      contentText.style.opacity = 1
      contentText.style.transform = directionDown
        ? "translateY(-5px)"
        : "translateY(+5px)"
      contentImg.style.opacity = 1
      contentImg.style.transform = directionDown
        ? "translateY(-5px)"
        : "translateY(+5px)"
    }, 400)
    // $("card-content-text").css("opacity", 1)

    // cardContentText.toggleClass("fadeInBottom")
  }

  return (
    <>
      <div
        id="carousel-card"
        class={`carousel-card ${data[currentPage].color}`}
        onMouseEnter={e => onMouseEnter(e)}
        onMouseLeave={e => onMouseLeave(e)}
        onWheel={e => onWheelCarousel(e)}
      >
        {data && (
          <div class="carousel-card-content">
            <div class="carousel-card-legend">
              {data.map((item, index) => (
                <i
                  class={`${currentPage === index ? "fill" : ""} ${
                    data[currentPage].color
                  }`}
                  onClick={e => onChangeCarousel(e, index)}
                ></i>
              ))}
            </div>
            <div
              id="card-content-text"
              class="carousel-card-text cssanimation sequence"
            >
              <div class="carousel-card-heading">{data[currentPage].title}</div>
              <div class="carousel-card-desc">{data[currentPage].desc}</div>
            </div>
            <div
              id="card-content-img"
              class="carousel-card-img cssanimation sequence"
            >
              <img
                src={data[currentPage].contentImg}
                alt={data[currentPage].title}
              />
            </div>
          </div>
        )}
      </div>
      <div className="carousel-wrapper-mobile">
        {data &&
          data?.map((item, index) => (
            <div
              key={index}
              id="carousel-card-mobile"
              class={`carousel-card ${item.color}`}
            >
              <div class="carousel-card-content">
                <div
                  id="card-content-text"
                  class="carousel-card-text cssanimation sequence"
                >
                  <div class="carousel-card-heading">{item?.title}</div>
                  <div class="carousel-card-desc">{item?.desc}</div>
                </div>
                <div
                  id="card-content-img"
                  class="carousel-card-img cssanimation sequence"
                >
                  <img src={item?.contentImg} alt={item?.title} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default VerticalCarousel
